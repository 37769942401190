<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="설비 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable && popupParam.noPlan === 'Y' && !updateMode && !disabled" label="설비선택" icon="add" @btnClicked="add"/>
            <c-btn v-if="editable && updateMode && !disabled" :showLoading="false" label="점검완료" icon="save" color="blue"  @btnClicked="resultComplete" />
            <c-btn
              v-if="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="result"
              :mappingType="saveType"
              label="점검결과 저장"
              icon="save"
              @beforeAction="saveInspection"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div v-if="editable && !result.equipmentCd" class="col-12">
            <c-label-text title="설비를 선택하세요."></c-label-text>
          </div>
          <div v-if="editable && result.plantName" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="사업장" :value="result.plantName"></c-label-text>
          </div>
          <div v-if="editable && result.equipmentTypeCd" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="설비유형" :value="result.equipmentTypeName"></c-label-text>
          </div>
          <div v-if="editable && result.equipmentCd" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="설비코드" :value="result.equipmentCd"></c-label-text>
          </div>
          <div v-if="editable && result.equipmentName" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="설비명" :value="result.equipmentName"></c-label-text>
          </div>
          <div v-if="editable && result.recentInspectionDate" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="이전점검일" :value="result.recentInspectionDate"></c-label-text>
          </div>
          <div v-if="editable && result.inspectionCycleName" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="점검주기" :value="result.inspectionCycleName"></c-label-text>
          </div>
          <div v-if="editable && result.nextMonth" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="차기법정예정월" :value="result.nextMonth"></c-label-text>
          </div>
          <div v-if="editable && result.relatedLawsName" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-label-text title="관련법규" :value="result.relatedLawsName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-12">
      <q-form ref="editForm">
        <c-card class="cardClassDetailForm" title="설비 점검결과" bgClass="">
          <!-- <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-if="editable && popupParam.noPlan === 'Y' && !updateMode && !disabled" label="설비선택" icon="add" @btnClicked="add"/>
              <c-btn v-if="editable && updateMode && !disabled" :showLoading="false" label="점검완료" icon="save" color="blue"  @btnClicked="resultComplete" />
              <c-btn
                v-if="editable && !disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="result"
                :mappingType="saveType"
                label="점검결과 저장"
                icon="save"
                @beforeAction="saveInspection"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template> -->
          <template slot="card-detail">
            <!-- <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <c-equip
                label="점검설비"
                :disabled="disabled"
                required
                v-model="result.equipmentCd"
              />
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                label="점검유형"
                :disabled="disabled"
                required
                codeGroupCd="MDM_CHECK_KIND_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="equipmentCheckKindCd"
                v-model="result.equipmentCheckKindCd"
              ></c-select>
            </div>
            <!-- <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <c-field
                :disabled="disabled"
                required
                label="점검예정자"
                name="checkScheduleUserId"
                type="user" 
                v-model="result.checkScheduleUserId">
              </c-field>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                label="점검예정일"
                name="checkScheduleDate"
                v-model="result.checkScheduleDate">
              </c-datepicker>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-dept
                label="점검부서"
                :required="true"
                :disabled="disabled"
                v-model="result.checkDeptCd"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-field
                :required="true"
                :disabled="disabled"
                label="실제점검자"
                name="checkUserId"
                type="user" 
                v-model="result.checkUserId">
              </c-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                label="실제점검일"
                name="checkDate"
                v-model="result.checkDate">
              </c-datepicker>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-moc 
                :disabled="disabled"
                :editable="editable"
                label="MOC 번호"
                name="sopMocId"
                v-model="result.sopMocId">
              </c-moc>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                codeGroupCd="MIM_CHECK_RESULT_CD2"
                :editable="editable"
                :disabled="disabled"
                required
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="checkResultCd"
                label="점검결과"
                v-model="result.checkResultCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-plant
                :disabled="disabled"
                v-model="result.plantCd"
              />
            </div>
            <!-- <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <c-select
                :comboItems="chkRsltYnItems"
                :editable="editable"
                required
                :disabled="disabled"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="checkCompleteFlag"
                label="점검완료여부"
                v-model="result.checkCompleteFlag"
              ></c-select>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                label="점검결과요약"
                name="checkResultSummary"
                v-model="result.checkResultSummary">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </q-form>
  </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
        align="left"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :result="result"
          />
        </template>
      </c-tab>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        noPlan: 'N',
        multiple: false,
        minEquipmentCheckId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentCheckId: '',
        plantCd: '',
        plantName: '',
        checkDeptCd: '',
        checkDeptName: '',  
        checkUserId: '',
        checkUserName: '',
        sopMocId: '',
        equipmentCheckKindCd: null,
        equipmentCheckKindNm: '',
        checkStatusCd: '',
        checkScheduleUserName: '', 
        checkScheduleUserId: '',
        checkScheduleDate: '',
        equipmentTypeCd: '', 
        equipmentCd: '', 
        equipmentName: '',
        inspectionCycleName: '',
        inspectionCycleCd: '',
        recentInspectionDate: '',
        relatedLaws: '',
        regUserId: '',
        chgUserId: '',
        noPlanResultEnrollFlag: 'Y',
        checkResultCd: null,
        checkCompleteFlag: null,
        checkDate: '',

        equipmentResultList: [],
        equipmentImproveModels: []
      },
      tab: 'resultItemTab',
      tabItems: [
        { name: 'resultItemTab', icon: 'list', label: '설비별 점검항목', component: () => import(`${'./equipmentResultItemTab.vue'}`) },
        { name: 'imprTab', icon: 'groups', label: '개선관리', component: () => import(`${'./equipmentCausePrevention.vue'}`) },
      ],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkRsltYnItems: [],

      saveUrl: 'transactionConfig.sop.min.equipment.result.insert.url',
      saveType: 'POST',
      isSave: false,
      insertUrl: '',
      updateUrl: '',
      getUrl: '',
      getResultItemUrl: '',
      title: '',
      updateMode: false,
    };
  },
  computed: {
    disabled() {
      return this.result.checkStatusCd === 'MCSC000015'
    }
  },
  watch: {
    'result.equipmentCheckKindCd'() {
      if (!this.result.equipmentCd) {
        this.result.equipmentResultList = [];
        return;
      }
      this.$http.url = this.$format(this.getResultItemUrl, this.result.equipmentCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.result.equipmentResultList = this.$_.clone(_result.data);
      },);
    },
    'result.equipmentCd'() {
      if (!this.result.equipmentCd) {
        this.result.equipmentResultList = [];
        return;
      }
      this.$http.url = this.$format(this.getResultItemUrl, this.result.equipmentCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.result.equipmentResultList = this.$_.clone(_result.data);
      },);
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.min.equipment.plan.get.url;
      this.getResultItemUrl = selectConfig.sop.min.equipment.result.item.get.url;
      this.insertUrl = transactionConfig.sop.min.equipment.result.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.update.url;
      this.completeUrl = transactionConfig.sop.min.equipment.result.complete.url;
      // code setting
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ]
      // list setting
      this.title = this.popupParam.title;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.minEquipmentCheckId) {
        this.grid.data = [];
        this.$http.url = this.getUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          minEquipmentCheckId: this.popupParam.minEquipmentCheckId,
          equipmentCd: this.popupParam.equipmentCd,
        }
        this.$http.request((_result) => {
          this.result = _result.data;
          this.updateMode = true;
        },);
      }
    },
    rowClick(row) {
      this.result = this.$_.clone(row);
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
          this.result.minEquipmentCheckId= ''
          this.result.plantCd= data[0].plantCd;
          this.result.plantName= data[0].plantName;
          this.result.checkDeptCd= data[0].managementDepts,
          this.result.checkDeptName= data[0].managementDeptName;  
          this.result.checkStatusCd= 'MCSC000005';
          this.result.checkScheduleUserName= ''; 
          this.result.checkScheduleUserId= '';
          this.result.checkScheduleDate= '';
          this.result.equipmentTypeCd= data[0].equipmentTypeCd; 
          this.result.equipmentTypeName= data[0].equipmentTypeName; 
          this.result.equipmentCd= data[0].equipmentCd; 
          this.result.equipmentName= data[0].equipmentName;
          this.result.inspectionCycleName= data[0].inspectionCycleName;
          this.result.inspectionCycleCd= data[0].inspectionCycleCd;
          this.result.recentInspectionDate= data[0].recentInspectionDate;
          this.result.relatedLaws= data[0].relatedLaws;
          this.result.regUserId= this.$store.getters.user.userId;
          this.result.chgUserId= this.$store.getters.user.userId;
          this.result.relatedLawsName = data[0].relatedLawsName;
      }
    },
    saveInspection() {
      if (this.popupParam.minEquipmentCheckId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      if (this.popupParam.noPlan === 'Y') {
        this.result.noPlanResultEnrollFlag = 'Y';
      }
      if (!this.result.equipmentCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비를 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      } 
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.minEquipmentCheckId = result.data
      this.getDetail();
    },
    
    addItem() {
      this.result.equipmentResultList.splice(0, 0, {
        minEquipmentCheckId: '',
        minEquipmentTypeCheckItemId: '',
        checkItemName: '',
        checkWayName: '',
        checkResult: '',
        judgmentResultTypeCd: '',
        editFlag: 'C',
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.result.equipmentResultList = this.$_.reject(this.result.equipmentResultList, item)
        })
      }
    },
    btnRecord() {
      this.popupOptions.target = () =>
      import(`${"@/pages/mdm/fim/equipmentPreview.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '40%';
      this.popupOptions.param = this.result;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    resultComplete() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '점검완료 하시겠습니까?',
            // TODO : 점검완료 하시겠습니까?
            type: 'warning', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.result.checkCompleteFlag = 'Y';
              this.$http.url = this.completeUrl;
              this.$http.type = 'PUT';
              this.$http.param = this.result;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getDetail();
              },);
            },
            // 취소 callback 함수
            cancelCallback: () => {
          },
        });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
        
    },
    // linkClick() {
    //   this.popupOptions.title = 'MOC 상세'; // 교육과정 상세
    //   this.popupOptions.param = {
    //     type: 'N'
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocDetail.vue'}`);
    //   this.popupOptions.width = '95%';
    //   this.popupOptions.height = '500px';
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeLinkPopup;
    // },
    closeLinkPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
